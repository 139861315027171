import { Button, Form, Input, InputNumber } from "antd";
import "./EditProfile.scss";
import { useNavigate } from "react-router-dom";

export default function EditProfile() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="page-header">Edit Details</div>

      <Form className="edit-profile-form">
        <Form.Item
          label="Mobile"
          name="mobile"
          rules={[
            {
              validator: (_, value) => {
                if (!value || String(value).length !== 10)
                  return Promise.reject(
                    "Please enter a valid (10-digit) phone number"
                  );
                else return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber controls={false} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ type: "email", message: "Please enter a valid email" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="PAN"
          name="pan"
          rules={[
            {
              validator: (_, value) => {
                if (!value || value.length !== 10)
                  return Promise.reject(
                    "Please enter a valid (10-character) PAN number"
                  );
                else return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="GSTIN"
          name="gstin"
          rules={[
            {
              validator: (_, value) => {
                if (!value || value.length !== 15)
                  return Promise.reject(
                    "Please enter a valid (15-character) GSTIN number"
                  );
                else return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          gap: 40,
        }}
      >
        <Button onClick={() => navigate(-1)}>Cancel</Button>
        <Button
          style={{ background: "#2369CC", color: "white", borderRadius: 4 }}
        >
          Update
        </Button>
      </div>
    </div>
  );
}
