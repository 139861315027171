import React, { useEffect, useState } from "react";
import "./Contact.scss";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import { toast } from "react-toastify";
import ContactRow from "./ContactRow";
import ListLoader from "../../re-usables/loaders/ListLoader";

export default function Contact() {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const appFlavour = process.env.REACT_APP_FLAVOUR;

  const manmulContacts = [
    {
      DESG: "Indent Section",
      CONTACT_NO: "+91-8767543432",
    },
    {
      DESG: "Route Supervisor",
      CONTACT_NO: "+91-7565754342",
    },
    {
      DESG: "Assistant Manager",
      CONTACT_NO: "+91-8767543432",
    },
    {
      DESG: "Deputy Manager",
      CONTACT_NO: "+91-7765435432",
    },
    {
      DESG: "App developer",
      CONTACT_NO: "Siri Technologies Mysore 8884402530",
    },
  ];

  const getContacts = async () => {
    try {
      setLoading(true);
      const res = await getRequest(
        urls.contactList(localStorage.getItem("userId"))
      );
      setContacts(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Something went wrong...Try again.");
    }
  };

  useEffect(() => {
    if (appFlavour === "CHAMUL") getContacts();
    else setContacts(manmulContacts);
  }, []);

  return (
    <div className="contact-us">
      {loading && (
        <div style={{ height: 300, overflow: "hidden" }}>
          <ListLoader />
        </div>
      )}
      {contacts.map((item) => (
        <ContactRow rowItem={item} />
      ))}
    </div>
  );
}
