import { DatePicker, Form, Input } from "antd";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar-icon.svg";
import moment from "moment";

export default function RequestInvoice() {
  let customerId = localStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  const [requestForm] = Form.useForm();

  const requestInvoice = async (values) => {
    try {
      setLoading(true);
      let { fromDate, toDate, email } = values;
      let formattedFormDate = fromDate.format("DD-MMM-YY");
      let formattedToDate = toDate.format("DD-MMM-YY");
      const res = await getRequest(
        urls.requestInvoice(
          customerId,
          formattedFormDate,
          formattedToDate,
          email
        )
      );
      setResMsg(res);
      requestForm.resetFields();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  if (resMsg)
    return (
      <div className="invoice-message">
        <p>{resMsg}</p>
        <button className="primary-btn" onClick={() => setResMsg(null)}>
          Got it
        </button>
      </div>
    );
  return (
    <div>
      <div className="page-header">Request Invoice</div>
      <div style={{ fontSize: 10, margin: "5px 0px 10px 0px" }}>
        &#40;Note: Invoice can be requested for a maximum period of 10 days&#41;
      </div>
      <Form
        className="invoice-form"
        form={requestForm}
        onFinish={requestInvoice}
        requiredMark={false}
      >
        <Form.Item
          label="From Date"
          name={"fromDate"}
          rules={[{ required: true, message: "Please select from date" }]}
        >
          <DatePicker suffixIcon={<CalendarIcon />} format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item
          label="To Date"
          name={"toDate"}
          rules={[{ required: true, message: "Please select to date" }]}
        >
          <DatePicker suffixIcon={<CalendarIcon />} format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item
          label="Email Id"
          name={"email"}
          rules={[
            { required: true, message: "Please enter your email id" },
            {
              type: "email",
              message: "It is not a valid E-mail",
            },
          ]}
          validateFirst={true}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
      </Form>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="primary-btn"
          onClick={() => requestForm.submit()}
          disabled={loading}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
