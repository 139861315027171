import React from "react";
import Skeleton from "react-loading-skeleton";
export default function ListLoader() {
  let list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  let item = (
    <div style={{ display: "flex", gap: 5, margin: "20px 0" }}>
      <div style={{ width: 80, height: 60 }}>
        <Skeleton />
      </div>
      <div style={{ width: "100%" }}>
        <div style={{ width: "100%", height: 17 }}>
          <Skeleton />
        </div>
        <div style={{ width: "100%", height: 17, margin: "5px 0" }}>
          <Skeleton />
        </div>
        <div
          style={{
            display: "flex",
            gap: 5,
            width: "100%",
            justifyContent: "space-between",
            marginTop: 5,
          }}
        >
          <div style={{ flex: 1, height: 17 }}>
            {" "}
            <Skeleton style={{ height: 17 }} />
          </div>
          <div style={{ flex: 1, height: 17 }}>
            {" "}
            <Skeleton style={{ height: 17 }} />
          </div>
        </div>
      </div>
    </div>
  );

  return list.map((i) => item);
}
