import { Spin } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL, getRequest } from "../../../http/axiosConfig/config";
import { v4 as uuid } from "uuid";

export default function Payment() {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const amount = location.state?.amount || null;
  const formattedAmount = parseFloat(parseFloat(amount).toFixed(2));
  const [hasError, setHasError] = useState(false);

  const handlePayment = async () => {
    try {
      let params = null;
      if (location.state?.billNo) {
        params = { bill_no: location.state.billNo };
      }
      setHasError(false);
      const order = await getRequest(
        `${BASE_URL}/create_order.php?customer_code=${userId}&amount=${formattedAmount}`,
        { params: params }
      );

      if (!order || !order.length || !order[0].ORDER_ID)
        throw new Error("Order Id not returned by server");

      // const encryptedVal = await getRequest(
      //   `https://manmul.in/get_enc_val.php?order_Id=${order[0].ORDER_ID}&amount=${formattedAmount}`
      // );
      // if (!encryptedVal)
      //   throw new Error("Encrypted value not returnrd by server");

      // window.location.replace(
      //   `https://secure.ccavenue.com/transaction.do?command=initiateTransaction&encRequest=${encryptedVal}&access_code=${order[0].ACCESS_CODE}`
      // );

      const form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", `${BASE_URL}/ccavRequestHandler.php`);
      form.style.display = "none";

      const tid = document.createElement("input");
      tid.setAttribute("type", "hidden");
      tid.setAttribute("name", "order_uid");
      tid.setAttribute("value", uuid());
      tid.setAttribute("readonly", true);
      form.appendChild(tid);

      const merchant_id = document.createElement("input");
      merchant_id.setAttribute("type", "hidden");
      merchant_id.setAttribute("name", "merchant_id");
      merchant_id.setAttribute("value", order[0].MERCHANT_ID);
      form.appendChild(merchant_id);

      const order_id = document.createElement("input");
      order_id.setAttribute("type", "hidden");
      order_id.setAttribute("name", "order_id");
      order_id.setAttribute("value", order[0].ORDER_ID);
      form.appendChild(order_id);

      // const billing_name = document.createElement("input");
      // billing_name.setAttribute("type", "hidden");
      // billing_name.setAttribute("name", "billing_name");
      // billing_name.setAttribute("value", order[0].CUSTOMER_NAME);
      // form.appendChild(billing_name);

      // const billing_address = document.createElement("input");
      // billing_address.setAttribute("type", "hidden");
      // billing_address.setAttribute("name", "billing_address");
      // billing_address.setAttribute("value", order[0].ADDR);
      // form.appendChild(billing_address);

      // const billing_city = document.createElement("input");
      // billing_city.setAttribute("type", "hidden");
      // billing_city.setAttribute("name", "billing_city");
      // billing_city.setAttribute("value", order[0].CITY);
      // form.appendChild(billing_city);

      // const billing_state = document.createElement("input");
      // billing_state.setAttribute("type", "hidden");
      // billing_state.setAttribute("name", "billing_state");
      // billing_state.setAttribute("value", order[0].STATE);
      // form.appendChild(billing_state);

      // const billing_zip = document.createElement("input");
      // billing_zip.setAttribute("type", "hidden");
      // billing_zip.setAttribute("name", "billing_zip");
      // billing_zip.setAttribute("value", order[0].ZIP);
      // form.appendChild(billing_zip);

      // const billing_country = document.createElement("input");
      // billing_country.setAttribute("type", "hidden");
      // billing_country.setAttribute("name", "billing_country");
      // billing_country.setAttribute("value", order[0].COUNTRY);
      // form.appendChild(billing_country);

      // const billing_tel = document.createElement("input");
      // billing_tel.setAttribute("type", "hidden");
      // billing_tel.setAttribute("name", "billing_tel");
      // billing_tel.setAttribute("value", order[0].TEL);
      // form.appendChild(billing_tel);

      // const billing_email = document.createElement("input");
      // billing_email.setAttribute("type", "hidden");
      // billing_email.setAttribute("name", "billing_email");
      // billing_email.setAttribute("value", order[0].EMAIL);
      // form.appendChild(billing_email);

      const amount = document.createElement("input");
      amount.setAttribute("type", "hidden");
      amount.setAttribute("name", "amount");
      amount.setAttribute("value", formattedAmount);
      form.appendChild(amount);

      const currency = document.createElement("input");
      currency.setAttribute("type", "hidden");
      currency.setAttribute("name", "currency");
      currency.setAttribute("value", "INR");
      form.appendChild(currency);

      const language = document.createElement("input");
      language.setAttribute("type", "hidden");
      language.setAttribute("name", "language");
      language.setAttribute("value", "EN");
      form.appendChild(language);

      const redirect_url = document.createElement("input");
      redirect_url.setAttribute("type", "hidden");
      redirect_url.setAttribute("name", "redirect_url");
      redirect_url.setAttribute("value", `${BASE_URL}/ccavResponseHandler.php`);
      form.appendChild(redirect_url);

      const cancel_url = document.createElement("input");
      cancel_url.setAttribute("type", "hidden");
      cancel_url.setAttribute("name", "cancel_url");
      cancel_url.setAttribute("value", `${BASE_URL}/ccavResponseHandler.php`);
      form.appendChild(cancel_url);

      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      console.log(error);
      setHasError(true);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("initialPayment")) {
      sessionStorage.removeItem("initialPayment");
      handlePayment();
    } else navigate("/indent", { replace: true });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100%",
      }}
    >
      {!hasError ? (
        <Spin tip="Routing to secure payment gateway..." size="large"></Spin>
      ) : (
        <div
          style={{
            display: "flex",
            gap: 10,
            alignItems: "center",
            flexDirection: "column",
            fontWeight: 500,
          }}
        >
          <span
            style={{
              height: 40,
              width: 40,
              background: "#ff5e5e",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 20,
              color: "white",
              fontWeight: 600,
            }}
          >
            X
          </span>
          Something went wrong
          <div style={{ display: "flex", gap: 20, marginTop: 20 }}>
            <button
              className="secondary-btn"
              style={{ width: 122, height: 35, padding: "0px 20px" }}
              onClick={() => navigate(-1, { replace: true })}
            >
              Back
            </button>
            <button
              className="primary-btn"
              style={{ width: 122, height: 35, padding: "0px 20px" }}
              onClick={() => handlePayment()}
            >
              Try again
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
