import { Empty, Modal } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { BASE_URL, getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import Card from "../../re-usables/card/Card";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete-icon.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download-icon.svg";

export default function AllNotifications() {
  const userId = localStorage.getItem("userId");
  const [allNotifications, setAllNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const { confirm } = Modal;

  const getAllNotifications = async () => {
    try {
      setLoading(true);
      const response = await getRequest(urls.getAllNotifications(userId));
      setAllNotifications(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong, visit this page later");
    }
  };

  const deleteNotification = async (msgId) => {
    try {
      setLoading(true);
      await getRequest(urls.deleteNotification(userId, msgId));
      getAllNotifications();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong, try again");
    }
  };

  const deleteAllNotification = async () => {
    try {
      setLoading(true);
      await getRequest(urls.deleteAllNotifications(userId));
      getAllNotifications();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong, try again");
    }
  };

  const showDeleteConfirm = (msgId) => {
    confirm({
      title: "Delete notification",
      //   icon: <ExclamationCircleFilled />,
      content: `Are you sure delete ${msgId ? "this" : "all"} notification${
        msgId ? "" : "s"
      }?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      centered: true,
      onOk() {
        msgId ? deleteNotification(msgId) : deleteAllNotification();
      },
    });
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  return (
    <div className="notifications" style={{ margin: 0 }}>
      <div
        className="notification-header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        Notifications
        <div className="clear-all" onClick={() => showDeleteConfirm()}>
          X Clear all
        </div>
      </div>
      <div className="notifications-all-scroll-area">
        {allNotifications.length ? (
          allNotifications.map((item) => (
            <Card>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 10,
                  fontSize: 13,
                }}
              >
                <div className="message">{item.MSG}</div>

                <div
                  className="action-button-container"
                  style={{ display: "flex", gap: 5 }}
                >
                  {/* ---------download icon-------------- */}
                  {item.ATTACH_FILE ? (
                    <a
                      href={`${BASE_URL}/notifications/${item.ATTACH_FILE}`}
                      download={item.ATTACH_FILE}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div style={{ padding: "0 10px" }}>
                        <DownloadIcon />
                      </div>
                    </a>
                  ) : null}
                  {/* ---------delete icon-------------- */}
                  <div
                    style={{ minWidth: 20 }}
                    onClick={() => showDeleteConfirm(item.MSG_ID)}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            </Card>
          ))
        ) : (
          <Empty description="No notifications" />
        )}
      </div>
    </div>
  );
}
