import React, { useEffect, useState } from "react";
import "./Notifications.scss";
import Card from "../../re-usables/card/Card";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import { Empty } from "antd";
import moment from "moment";
import { BASE_URL } from "../../../http/axiosConfig/config";
import { ReactComponent as WarningIcon } from "../../../assets/icons/warning-icon.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download-icon.svg";
import ListLoader from "../../re-usables/loaders/ListLoader";

export default function Notifications(props) {
  const { agType } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const getNotifications = async () => {
    try {
      setLoading(true);
      const response = await getRequest(urls.getNotifications(props.userId));
      setNotifications(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div>
      {props.selectedBatch && props.indentDate ? (
        <div className="start-indent">
          {moment().isBetween(
            moment(props.selectedBatch.ST_TIME, "hh.mm A"),
            moment(props.selectedBatch.CUT_OFF, "hh.mm A"),
            "minutes"
          ) ? (
            <button className="primary-btn" onClick={() => navigate("/indent")}>
              Start Indent
            </button>
          ) : null}

          {moment().isBefore(moment(props.selectedBatch.ST_TIME, "hh.mm A")) ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="error-container">
                <WarningIcon /> This indent has not yet started.
              </div>
              <button
                className="primary-btn"
                onClick={() =>
                  navigate("/indent", { state: { readOnly: true } })
                }
              >
                View Indent
              </button>
            </div>
          ) : null}

          {moment().isAfter(moment(props.selectedBatch.CUT_OFF, "hh.mm A")) ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="error-container">
                <WarningIcon /> Today's indent for this shift is closed.
              </div>
              <button
                className="primary-btn"
                onClick={() =>
                  navigate("/indent", { state: { readOnly: true } })
                }
              >
                View Indent
              </button>
            </div>
          ) : null}
        </div>
      ) : null}

      {(agType && agType === "4") || (agType && agType === "6") ? (
        <div className="start-indent">
          <button
            className="primary-btn"
            // onClick={() => navigate("/indent")}
          >
            {props.tcdStatus && props.tcdStatus.INDENT_NUMBER
              ? "Edit Order"
              : "Place new order"}
          </button>
        </div>
      ) : null}

      <div className="notifications">
        <div className="notification-header">Notifications</div>
        <div className="notifications-scroll-area">
          {loading ? <ListLoader /> : null}
          {!loading && notifications.length ? (
            notifications.map((item) => (
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 10,
                    fontSize: 13,
                  }}
                >
                  <div className="message">{item.MSG}</div>
                  {item.ATTACH_FILE ? (
                    <a
                      href={`${BASE_URL}/notifications/${item.ATTACH_FILE}`}
                      download={item.ATTACH_FILE}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div style={{ padding: "0 10px" }}>
                        <DownloadIcon />
                      </div>
                    </a>
                  ) : null}
                </div>
              </Card>
            ))
          ) : (
            <Empty description="No new notifications" />
          )}
        </div>
      </div>
    </div>
  );
}
