import React from "react";
import "./Reports.scss";
import { ReactComponent as RateChartIcon } from "../../../assets/icons/rate-chart-icon.svg";
import { ReactComponent as InvoiceIcon } from "../../../assets/icons/invoice-icon.svg";
import { ReactComponent as LedgerIcon } from "../../../assets/icons/ledger-tile-icon.svg";
import { ReactComponent as TransactionIcon } from "../../../assets/icons/transaction-icon.svg";
import { ReactComponent as TurnoverIcon } from "../../../assets/icons/turnover-icon.svg";
import Tile from "./Tile";

export default function Reports() {
  const creditLimit = JSON.parse(sessionStorage.getItem("creditLimit"));
  return (
    <>
      <div className="page-header">Reports</div>
      <div className="reports-main">
        <Tile
          navigateTo="/rate-chart"
          title="Rate Chart"
          icon={<RateChartIcon />}
        />
        <Tile
          navigateTo="/request-invoice"
          title="Request Invoice"
          icon={<InvoiceIcon />}
        />
        <Tile navigateTo="/ledger" title="Ledger" icon={<LedgerIcon />} />

        <Tile
          navigateTo="/turnover-statement"
          title="Turnover"
          icon={<TurnoverIcon />}
        />

        {creditLimit[0].PAY_MODE === "B" ? (
          <Tile
            navigateTo="/online-transactions"
            title="Transactions"
            icon={<TransactionIcon />}
          />
        ) : null}
      </div>
    </>
  );
}
