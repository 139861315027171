import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL, getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import "./ProductDetails.scss";
import { SyncOutlined } from "@ant-design/icons";

export default function ProductDetails() {
  const inr = <span style={{ fontFamily: "sans-serif" }}>&#x20B9;</span>;
  const [loading, setLoading] = useState(true);
  const [productDetails, setProductDetails] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { packetCode, rateCatag } = location.state;

  const getProductDetails = async () => {
    try {
      setLoading(true);
      const response = await getRequest(
        urls.getProductDetail(packetCode, rateCatag)
      );
      setProductDetails(response);
      setImageUrl(`${BASE_URL}/images/${response.img_file}`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const goBack = () => {
    navigate(-1, { replace: true });
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  useEffect(() => {
    getProductDetails();
  }, [packetCode, rateCatag]);

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <SyncOutlined spin style={{ fontSize: 30 }} />
      </div>
    );
  return (
    <div className="product-detail">
      <div className="close-btn" onClick={goBack}>
        X
      </div>
      <div className="product-img">
        <div className="img-background">
          <img
            src={imageUrl}
            alt={productDetails.packet_name}
            onError={(e) =>
              (e.target.src = require("../../../assets/images/error-loading-image.jpg"))
            }
          />
        </div>
      </div>
      <div className="product-name">{productDetails.packet_name}</div>

      <div className="product-price">
        {inr} {productDetails.your_rate}
      </div>
      <div className="product-desc">
        <div className="sub-heading">
          <Divider orientation="left" orientationMargin={0}>
            Product Details
          </Divider>
        </div>
        <div className="flex-row">
          <div className="key">MRP:</div>
          <div className="value">
            {inr} {productDetails.mrp}
          </div>
        </div>
        <div className="flex-row">
          <div className="key">Current Stock:</div>
          <div className="value">{productDetails.current_stock} units</div>
        </div>
        <div className="flex-row">
          <div className="key">Shelf Life:</div>
          <div className="value">{productDetails.shelf_life} day(s)</div>
        </div>
      </div>
      <div className="back-btn">
        <button className="primary-btn" onClick={goBack}>
          Back
        </button>
      </div>
    </div>
  );
}
