import React, { useState } from "react";
import { DatePicker, Empty, Form, Spin, Table } from "antd";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar-icon.svg";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import DownloadReport from "./DownloadReport";
import { useNavigate } from "react-router-dom";

export default function TurnoverStatement() {
  let customerId = localStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const [turnoverData, setTurnoverData] = useState(null);
  const [dateForm] = Form.useForm();
  const navigate = useNavigate();

  const getTurnoverStatement = async (values) => {
    try {
      setLoading(true);
      let formattedFormDate = values.fromDate.format("DD-MMM-YY");
      let formattedToDate = values.toDate.format("DD-MMM-YY");
      const res = await getRequest(
        urls.turnOverStatement(customerId, formattedFormDate, formattedToDate)
      );
      setTurnoverData(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const turnOverColumns = [
    { title: "Product", dataIndex: "PACKET_NAME" },
    {
      title: "Rate",
      dataIndex: "RATE",
      width: 60,
      align: "center",
    },
    {
      title: "Qty",
      dataIndex: "QTY_PKTS",
      width: 60,
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "AMOUNT",
      width: 75,
      align: "center",
    },
  ];

  return (
    <div>
      <div className="page-header">
        Turnover Statement{" "}
        {/* {turnoverData && turnoverData.length && !loading ? (
          <DownloadReport
            columns={turnOverColumns}
            data={turnoverData}
            reportName="Turnover Statement"
            to={dateForm.getFieldValue("toDate").format("DD/MM/YY")}
            from={dateForm.getFieldValue("fromDate").format("DD/MM/YY")}
          />
        ) : null} */}
      </div>

      <Form
        className="date-range-form"
        form={dateForm}
        layout={"horizontal"}
        onFinish={getTurnoverStatement}
        requiredMark={false}
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <Form.Item
          noStyle
          name={"fromDate"}
          rules={[{ required: true, message: "Please select from date" }]}
        >
          <DatePicker suffixIcon={<CalendarIcon />} format="DD/MM/YY" />
        </Form.Item>
        To
        <Form.Item
          noStyle
          name={"toDate"}
          rules={[{ required: true, message: "Please select to date" }]}
        >
          <DatePicker suffixIcon={<CalendarIcon />} format="DD/MM/YY" />
        </Form.Item>
        <Form.Item noStyle>
          <button
            className="primary-btn"
            style={{
              width: 60,
              padding: 5,
              boxShadow: "none",
              borderRadius: "none",
            }}
            onClick={() => dateForm.submit()}
          >
            Go
          </button>
        </Form.Item>
      </Form>
      {!loading && turnoverData ? (
        <>
          <Table
            dataSource={loading ? [] : turnoverData}
            locale={{
              emptyText: loading ? <Spin /> : <Empty />,
            }}
            columns={turnOverColumns}
            bordered
            scroll={{ y: "calc(100vh - 420px)", x: 200 }}
            pagination={false}
          />
        </>
      ) : null}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      ) : null}
    </div>
  );
}
