import React, { createContext, useEffect, useState } from "react";
import moment from "moment";
import "./Home.scss";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Notifications from "../notification/Notifications";
import Reports from "../reports/Reports";
import Contact from "../contactUs/Contact";
import Indents from "../indent/Indents";
import { ReactComponent as HomeIcon } from "../../../assets/icons/home-icon.svg";
import { ReactComponent as ContactIcon } from "../../../assets/icons/contact-icon.svg";
import { ReactComponent as PaymentIcon } from "../../../assets/icons/payment-icon.svg";
import { ReactComponent as LedgerIcon } from "../../../assets/icons/ledger-icon.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/logout-icon.svg";
import { Divider, Drawer, Select } from "antd";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import formatEnum from "../../../helpers/enumFormatter";
import { HomeContext } from "../../../helpers/contexts/homeContext";
import ProductDetails from "../productDetails/ProductDetails";
import Categories from "../categories/Categories";
import CategoryItems from "../categoryItems/CategoryItems";
import Order from "../orders/Order";
import Payment from "../paymentGateway/Payment";
import SidebarLink from "./SidebarLink";
import AllNotifications from "../notification/AllNotifications";
import ChangePassword from "../changePassword/ChangePassword";
import ManualPayment from "../paymentGateway/ManualPayment";
import RateChart from "../reports/RateChart";
import RequestInvoice from "../reports/RequestInvoice";
import OnlineTransaction from "../reports/OnlineTransaction";
import Ledger from "../reports/Ledger";
import TurnoverStatement from "../reports/TurnoverStatement";
import DownloadReport from "../reports/DownloadReport";
import { EditFilled } from "@ant-design/icons";
import EditProfile from "../profile/EditProfile";

export default function Home() {
  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(
    JSON.parse(sessionStorage.getItem("selectedBatch")) || undefined
  );
  const [creditLimit, setCreditLimit] = useState("");
  const [indentDate, setIndentDate] = useState("");
  const [profileDetails, setProfileDetails] = useState("");
  const [tcdStatus, setTcdStatus] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userName = localStorage.getItem("userName");
  const userId = localStorage.getItem("userId");
  const agType = localStorage.getItem("agType");
  const appFlavour = process.env.REACT_APP_FLAVOUR;

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getBatches = async () => {
    try {
      const response = await getRequest(urls.getBatches(userId));
      setBatches(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getCreditLimit = async () => {
    try {
      const response = await getRequest(urls.getCreditLimit(userId));
      sessionStorage.setItem("creditLimit", JSON.stringify(response));
      setCreditLimit(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getIndentDate = async () => {
    try {
      const response = await getRequest(
        urls.getDate(selectedBatch.DIST_BATCH_NO)
      );
      setIndentDate(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getProfileDetails = async () => {
    try {
      const response = await getRequest(urls.getProfileDetails(userId));
      setProfileDetails(response[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getTcdStatus = async () => {
    try {
      const response = await getRequest(urls.tcdStatus(userId));
      setTcdStatus(response[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("auth")) navigate("login", { replace: true });
  }, [navigate, location]);

  useEffect(() => {
    getBatches();
    getCreditLimit();
    getProfileDetails();
    if (selectedBatch) getIndentDate();
    if ((agType && agType === "4") || (agType && agType === "6"))
      getTcdStatus();
  }, []);

  useEffect(() => {
    getIndentDate();
  }, [selectedBatch]);

  return (
    <div className="home-main">
      <div className="wrapper">
        <div className="home-top-section">
          <div className="user-info">
            <div className="user-info-text">
              <div className="user-name">{userName}</div>
              {appFlavour === "CHAMUL" ? (
                <div className="user-name">
                  Agent Code: {localStorage.getItem("agentCode") || ""}
                </div>
              ) : null}
              {selectedBatch ? (
                <div className="user-address">
                  Route: {selectedBatch.USERS_CODE}, {selectedBatch.ROUTE_NAME}
                </div>
              ) : null}
            </div>
            <div className="user-avatar" onClick={showDrawer}>
              <img
                src={require("../../../assets/images/test-profile-pic.png")}
                alt="user avatar"
              />
            </div>
          </div>
          {appFlavour !== "CHAMUL" ? (
            <div className="action-btns action-btns-home">
              <div className="credit">
                <div style={{ fontSize: 12 }}>Credit:</div>
                <div>
                  &#x20B9;{creditLimit.length && creditLimit[0].CREDIT_LIMIT}
                </div>
              </div>
              <div className="credit">
                <div style={{ fontSize: 12 }}>Your Due:</div>
                <div>
                  &#x20B9;{creditLimit.length && creditLimit[0].BALANCE}
                </div>
              </div>
            </div>
          ) : null}

          {!agType || agType !== "4" || agType !== "6" ? (
            <div className="batches">
              <Select
                name="select-batches"
                style={
                  appFlavour === "MYMUL"
                    ? { width: "100%" }
                    : appFlavour === "CHAMUL"
                    ? { width: "100%", marginTop: 10 }
                    : {}
                }
                placeholder="Select Batch"
                value={JSON.stringify(selectedBatch)}
                onChange={(val) => {
                  setSelectedBatch(JSON.parse(val));
                  sessionStorage.setItem("selectedBatch", val);
                  navigate("", { replace: true });
                }}
              >
                {batches.map((b, i) => (
                  <Select.Option value={JSON.stringify(b)} key={i}>
                    {formatEnum(b.DIST_BATCH_NAME) + " - " + b.CUT_OFF}
                  </Select.Option>
                ))}
              </Select>
            </div>
          ) : null}
        </div>
        <div className="current-date">
          {/* {moment().format("dddd, DD MMM YYYY")} */}
          {indentDate
            ? indentDate.indent_date
            : moment().format("dddd, DD MMMM YYYY")}
        </div>
      </div>
      <div className="home-content">
        <Routes>
          <Route
            path=""
            element={
              <Notifications
                selectedBatch={selectedBatch}
                indentDate={indentDate}
                userId={userId}
                tcdStatus={tcdStatus}
                agType={agType}
              />
            }
          />
          <Route path="allNotifications" element={<AllNotifications />} />
          <Route path="reports" element={<Reports />} />
          <Route path="contact" element={<Contact />} />
          <Route path="edit-profile" element={<EditProfile />} />
          <Route path="product-details" element={<ProductDetails />} />
          <Route path="categories" element={<Categories />} />
          <Route path="categoryItems" element={<CategoryItems />} />
          <Route path="Order" element={<Order />} />
          <Route path="payment" element={<Payment />} />
          <Route path="manual-payment" element={<ManualPayment />} />
          <Route path="changePassword" element={<ChangePassword />} />
          <Route path="rate-chart" element={<RateChart />} />
          <Route path="request-invoice" element={<RequestInvoice />} />
          <Route path="online-transactions" element={<OnlineTransaction />} />
          <Route path="ledger" element={<Ledger />} />
          <Route path="turnover-statement" element={<TurnoverStatement />} />
          <Route path="download-report" element={<DownloadReport />} />
          <Route
            path="indent"
            element={
              <HomeContext.Provider value={selectedBatch}>
                <Indents />
              </HomeContext.Provider>
            }
          />
        </Routes>
      </div>
      <div className="home-footer">
        <div className="footer-btn" onClick={() => navigate("")}>
          <HomeIcon />
          Home
        </div>
        <div className="footer-btn" onClick={() => navigate("reports")}>
          <LedgerIcon />
          Reports
        </div>
        <div className="footer-btn" onClick={() => navigate("manual-payment")}>
          <PaymentIcon />
          Payments
        </div>
        <div className="footer-btn" onClick={() => navigate("contact")}>
          <ContactIcon />
          Contacts
        </div>
      </div>

      <Drawer
        // title="Profile"
        className="side-bar"
        placement="right"
        onClose={onClose}
        open={open}
        width="80%"
        bodyStyle={{ backgroundColor: "#2369CC", color: "white" }}
        headerStyle={{
          backgroundColor: "#2369CC",
          color: "white",
          fontWeight: 600,
          border: "none",
        }}
      >
        <div className="user-avatar">
          <img
            src={require("../../../assets/images/test-profile-pic.png")}
            alt="user avatar"
          />
        </div>

        <div className="user-details">
          <div className="row-1">
            <div className="key">Name:</div>
            <div className="value">{profileDetails?.CUSTOMER_NAME}</div>
          </div>
          <div className="row-1">
            <div className="key">Agent Code:</div>
            <div className="value">{profileDetails?.USERS_CODE}</div>
          </div>
          <div className="row-1">
            <div className="key">Mobile:</div>
            <div className="value">{profileDetails?.MOBILE}</div>
          </div>
          <div className="row-1">
            <div className="key">Email:</div>
            <div className="value">{profileDetails?.EMAIL}</div>
          </div>
          <div className="row-1">
            <div className="key">GSTIN:</div>
            <div className="value">{profileDetails?.GSTIN}</div>
          </div>
          <div className="row-1">
            <div className="key">PAN:</div>
            <div className="value">{profileDetails?.PAN}</div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 5,
            }}
          >
            <Link to="edit-profile">
              <div
                onClick={onClose}
                style={{
                  background: "#1f467e",
                  padding: "0px 10px",
                  borderRadius: 10,
                  color: "white",
                }}
              >
                <EditFilled />
                <span> Edit</span>
              </div>
            </Link>
          </div>
        </div>

        <Divider style={{ background: "#5296f6", marginTop: 5 }} />

        <div className="side-bar-links">
          <SidebarLink path="reports" label="Reports" closeSidebar={onClose} />
          <SidebarLink
            path="allNotifications"
            label="Notifications"
            closeSidebar={onClose}
          />
          <SidebarLink
            path="changePassword"
            label="Change Password"
            closeSidebar={onClose}
          />
        </div>

        <div className="logout-container">
          <div
            className="logout-btn"
            onClick={() => {
              localStorage.clear();
              sessionStorage.clear();
              navigate("/login", { replace: true });
            }}
          >
            <LogoutIcon />
            Log Out
          </div>
          <span className="version-text">{process.env.REACT_APP_VERSION}</span>
        </div>
      </Drawer>
    </div>
  );
}
